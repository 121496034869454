import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/cpes`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(cpe) {
    return apiClient.post(`${cpe.id}`, cpe)
  },

  actualizarIspDeCpes(idsCpes, idRouter, idIsp) {
    return apiClient.post('cambio-isp', {
      ids_cpes: idsCpes,
      id_router: idRouter,
      id_isp: idIsp
    })
  },

  cpeJSON(idCpe, params) {
    params = params || {}
    return apiClient.get(`${idCpe}.json`, { params: params })
  },

  cpesJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/cpes.json`, { params: params })
  },

  nEnLinea() {
    return apiClient.get('n-en-linea')
  },

  nDesconectados() {
    return apiClient.get('n-desconectados')
  },

  disponiblesParaContratoJSON(params) {
    params = params || {}
    return apiClient.get('disponibles-para-contrato.json', { params: params })
  },

  eliminar(idCpe) {
    return apiClient.delete(`${idCpe}`)
  },

  guardar(cpe) {
    return apiClient.post('', cpe)
  },

  ping(idCpe, params) {
    params = params || {}
    return apiClient.get(`${idCpe}/ping`,{ params: params})
  },

  relsCpesYClientesJSON(params) {
    return apiClient.get('rels-cpes-y-clientes.json', { params: params })
  },

  resinc(idCpe) {
    return apiClient.post(`${idCpe}/resinc`)
  },

  vCpes1JSON(params) {
    params = params || {}
    return apiClient.get(`${API}/vcpes1.json`, { params: params })
  },

  listadoCPEsJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/listadocpes.json`, { params: params })
  },

  traficoJSON(idCpe){
    return apiClient.get(`${idCpe}/trafico.json`);
  }
}
